<template>
  <div>
    <div class="flex jc-end mb-4">
      <a-button type="primary" @click="editButtonClick" :loading="saveLoading">{{
        this.isEdit ? '保存' : '编辑'
      }}</a-button>
    </div>
    <div class="info-group" v-if="!isEdit">
      <div v-if="curRow.userFile">
        <info-item :label-width="labelWidth" label="头像">
          <img style="  width: 120px;height: 120px; border-radius: 50% !important;" :src="curRow.userFile.avatar" />
        </info-item>
        <info-item :label-width="labelWidth" label="聊天工具">
          <div v-if="curRow.userFile.qq">
            <a-icon type="qq" style="width:1vw;color:hsl(0deg 0% 75%);" />
            {{ curRow.userFile.qq }}
          </div>
          <div v-if="curRow.userFile.qqType">
            <a-icon type="wechat" style="width:1vw;color:hsl(0deg 0% 75%);" />
            {{ curRow.userFile.qqType }}
          </div>
        </info-item>
        <info-item :label-width="labelWidth" label="签约类型">
          {{ curRow.userFile.contractType }}
        </info-item>
        <info-item :label-width="labelWidth" label="签约日期">
          {{ curRow.userFile.contractDate }}
        </info-item>
        <info-item :label-width="labelWidth" label="合作时长">
          {{ curRow.userFile.duration || '小于1' }}个月
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="是否有全职工作"
          v-if="curRow.userFile.contractType == '意向全职' || curRow.userFile.contractType == '兼职'"
        >
          {{ curRow.userFile.workStatus == 1 ? '是' : '否' }}
        </info-item>
        <info-item :label-width="labelWidth" label="工作地城市">
          {{ getWorkCity(curRow.userFile.workCity) }}
        </info-item>
        <info-item v-if="typeShow" :label-width="labelWidth" label="擅长作品类型">
          <a-tag :key="item" v-for="item in curRow.userFile.goodAtProductionType">{{
            item == '其他' ? (curRow.userFile.skilledProductOther ? curRow.userFile.skilledProductOther : '其他') : item
          }}</a-tag>
        </info-item>
        <info-item v-if="typeShow" :label-width="labelWidth" label="抵触作品类型">
          <a-tag :key="item" v-for="item in curRow.userFile.badAtProductionType">{{
            item == '其他'
              ? curRow.userFile.unskilledProductOther
                ? curRow.userFile.unskilledProductOther
                : '其他'
              : item
          }}</a-tag>
        </info-item>
        <div style="position: relative;height:20px;padding-bottom: 42px;">
          <span style="  position: absolute; ;font-size:12px;top:0;color:#aca9a9"
            >下列信息仅BOSS及HR有权查看，请如实填写。</span
          >
        </div>
        <info-item :label-width="labelWidth" label="姓名">
          {{ curRow.userFile.userName }}
        </info-item>
        <info-item :label-width="labelWidth" label="性别">
          {{ curRow.userFile.sex == 1 ? '男' : curRow.userFile.sex == 0 ? '女' : '' }}
        </info-item>
        <info-item :label-width="labelWidth" label="出生日期">
          {{ curRow.userFile.bornDate }}
        </info-item>
        <info-item :label-width="labelWidth" label="国籍">
          {{ curRow.userFile.country }}
        </info-item>
<!--         <info-item :label-width="labelWidth" label="民族">
          {{ curRow.userFile.nation }}
        </info-item> -->
        <info-item :label-width="labelWidth" label="电话号码">
          {{ curRow.userFile.phone }}
        </info-item>
<!--         <info-item :label-width="labelWidth" label="学历">
          {{ curRow.userFile.education }}
        </info-item> -->
<!--         <info-item :label-width="labelWidth" label="毕业院校">
          {{ curRow.userFile.school }}
        </info-item> -->
<!--         <info-item :label-width="labelWidth" label="职位相关证书">
          {{ curRow.userFile.certificate }}
        </info-item> -->
        <!-- <info-item :label-width="labelWidth" label="证件号">
            {{ curRow.userFile.idCardNo }}
          </info-item> -->
<!--         <info-item :label-width="labelWidth" label="工作经历">
          {{ curRow.userFile.workExperience }}
        </info-item> -->
        <!--           <info-item :label-width="labelWidth" label="备注">
            {{ curRow.userFile.commentText }}
          </info-item> -->
        <template
          v-if="
            (country == 'qt' || country == 'gat') &&
              curRow.userFile.contractType != '意向全职' &&
              curRow.userFile.contractType != '兼职'
          "
        >
          <info-item :label-width="labelWidth" label="户名">
            {{ curRow.userFile.paymentName }}
          </info-item>
          <info-item :label-width="labelWidth" label="收款账号">
            {{ curRow.userFile.bankAccount }}
          </info-item>
          <info-item :label-width="labelWidth" label="银行名称">
            {{ curRow.userFile.bankName }}
          </info-item>
          <info-item :label-width="labelWidth" label="支行名称">
            {{ curRow.userFile.branchBank }}
          </info-item>
          <info-item :label-width="labelWidth" label="SWIFT CODE">
            {{ curRow.userFile.paymentBank }}
          </info-item>
          <info-item :label-width="labelWidth" label="银行地址">
            {{ curRow.userFile.bankAddress }}
          </info-item>
          <info-item :label-width="labelWidth" label="个人地址">
            {{ curRow.userFile.personalAddress }}
          </info-item>
        </template>
        <template v-else>
          <div>
            <info-item :label-width="labelWidth" label="收款账号">
              <div v-if="curRow.userFile.contractType == '意向全职' || curRow.userFile.contractType == '兼职'">
                {{
                  curRow.userFile.paymentType == 'ALIPAY'
                    ? '支付宝'
                    : curRow.userFile.paymentType == 'WECHAT'
                    ? '微信'
                    : curRow.userFile.paymentType == 'BANK'
                    ? '银行账户'
                    : curRow.userFile.paymentType == 'EN_ACCOUNT'
                    ? '全英账户'
                    : ''
                }}
              </div>
              <div
                v-if="
                  country != 'qt' &&
                    country != 'gat' &&
                    curRow.userFile.contractType != '意向全职' &&
                    curRow.userFile.contractType != '兼职'
                "
              >
                {{ curRow.userFile.alipayAccount }}
              </div>
              <div v-if="curRow.userFile.paymentType == 'ALIPAY'">{{ curRow.userFile.alipayAccount }}</div>
              <div v-if="curRow.userFile.paymentType == 'WECHAT'">{{ curRow.userFile.alipayAccount }}</div>
              <template
                v-if="
                  curRow.userFile.paymentType == 'BANK' &&
                    curRow.userFile.contractType != '全职' &&
                    curRow.userFile.contractType != '实习'
                "
              >
                <div v-if="country != 'qt' && country != 'gat'">{{ getBank(curRow.userFile.bankName) }}</div>
                <div v-if="country != 'qt' && country != 'gat'">{{ curRow.userFile.bankAccount }}</div>
                <div v-if="country != 'qt' && country != 'gat'">{{ curRow.userFile.paymentName }}</div>
              </template>
            </info-item>
            <template v-if="curRow.userFile.paymentType == 'EN_ACCOUNT'">
              <info-item :label-width="labelWidth" label="户名">
                {{ curRow.userFile.paymentName }}
              </info-item>
              <info-item :label-width="labelWidth" label="收款账号">
                {{ curRow.userFile.bankAccount }}
              </info-item>
              <info-item :label-width="labelWidth" label="银行名称">
                {{ curRow.userFile.bankName }}
              </info-item>
              <info-item :label-width="labelWidth" label="支行名称">
                {{ curRow.userFile.branchBank }}
              </info-item>
              <info-item :label-width="labelWidth" label="SWIFT CODE">
                {{ curRow.userFile.paymentBank }}
              </info-item>
              <info-item :label-width="labelWidth" label="银行地址">
                {{ curRow.userFile.bankAddress }}
              </info-item>
              <info-item :label-width="labelWidth" label="个人地址">
                {{ curRow.userFile.personalAddress }}
              </info-item>
            </template>
          </div>
        </template>
        <template
          v-if="
            curRow.userFile.paymentType == 'BANK' &&
              curRow.userFile.contractType != '全职' &&
              curRow.userFile.contractType != '实习' &&
              country == 'qt'
          "
        >
          <div>
            <info-item :label-width="labelWidth" label="名前片假名">
              {{ curRow.userFile.paymentName }}
            </info-item>
            <info-item :label-width="labelWidth" label="銀行名">
              {{ curRow.userFile.bankName }}
            </info-item>
            <info-item :label-width="labelWidth" label="店番号">
              {{ curRow.userFile.bankAccount }}
            </info-item>
            <info-item :label-width="labelWidth" label="支店名">
              {{ curRow.userFile.branchBank }}
            </info-item>
            <info-item :label-width="labelWidth" label="口座番号">
              {{ curRow.userFile.bankAddress }}
            </info-item>
            <info-item :label-width="labelWidth" label="預金種別">
              {{ curRow.userFile.personalAddress }}
            </info-item>
          </div>
        </template>
      </div>
    </div>

    <a-form-model
      slot="form"
      :model="formData.userFile"
      ref="dataForm"
      :rules="validatorRules"
      v-bind="layout"
      v-if="isEdit && isFormShow"
    >
      <a-form-model-item label="头像" prop="avatar" class="user-photo">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeCroUpload"
          @change="handleUpChange"
        >
          <div v-if="formData.userFile.avatar" class="img-div">
            <img ref="showImg" :src="formData.userFile.avatar" alt="avatar" class="upload_img" />
          </div>
          <div v-else>
            <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传头像
            </div>
          </div>
        </a-upload>
        <a-input
          allow-clear
          v-model="textMsg"
          @change="
            () => {
              this.textMsg = ''
            }
          "
          placeholder="请粘贴图片或链接"
          @paste.native.capture.prevent="pasting"
        />
      </a-form-model-item>
      <a-form-model-item label="聊天工具" prop="qq">
        <a-input placeholder="请至少填写一种聊天工具" @blur="qqChange" v-model="formData.userFile.qq">
          <a-icon slot="prefix" type="qq" style="width:1vw;position: relative;left: -5px;color:hsl(0deg 0% 75%);" />
        </a-input>
        <a-input placeholder="请至少填写一种聊天工具" @blur="qqChange" v-model="formData.userFile.qqType">
          <a-icon slot="prefix" type="wechat" style="width:1vw;position: relative;left: -5px;color:hsl(0deg 0% 75%);" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="签约类型" prop="contractType">
        <a-select @change="contractTypeChange" placeholder="签约类型" v-model="formData.userFile.contractType">
          <a-select-option value="全职">全职</a-select-option>
          <a-select-option value="兼职">兼职</a-select-option>
          <a-select-option value="意向全职">意向全职</a-select-option>
          <a-select-option value="实习">实习</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="签约日期" prop="contractDate">
        <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.contractDate" />
      </a-form-model-item>
      <!--           <a-form-model-item v-if="formData.userFile.contractType=='全职'" label="是否有试用期" prop="haveProbation">
            <a-radio-group v-model="formData.userFile.haveProbation">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="formData.userFile.haveProbation==1" label="试用期结束日期" prop="probationEndDate">
            <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.probationEndDate" :disabled="formData.userFile.contractType == '兼职'" />
          </a-form-model-item> -->
      <a-form-model-item
        prop="workStatus"
        label="是否有全职工作"
        v-if="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
      >
        <a-radio-group v-model="formData.userFile.workStatus">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="工作地城市" prop="workCity">
        <a-cascader
          :options="options"
          allowClear
          :defaultValue="formData.userFile.workCity"
          change-on-select
          :show-search="{ filter }"
          placeholder="请选择工作地城市"
          @change="onChange"
        />
      </a-form-model-item>
      <!--           <a-form-model-item label="每周可工作日">
            <a-select mode="multiple" placeholder="每周可工作日" v-model="formData.userFile.workDays" @change="workDaysChange">
              <a-select-option :value="1">周一</a-select-option>
              <a-select-option :value="2">周二</a-select-option>
              <a-select-option :value="3">周三</a-select-option>
              <a-select-option :value="4">周四</a-select-option>
              <a-select-option :value="5">周五</a-select-option>
              <a-select-option :value="6">周六</a-select-option>
              <a-select-option :value="7">周日</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="工作日每天可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.workdayAvailableCount" @change="workCountChange" />
          </a-form-model-item>
          <a-form-model-item label="周末可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekendAvailableCount" :disabled="dis" @change="workCountChange" />
          </a-form-model-item>
          <a-form-model-item label="当周可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekAvailableCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当周已安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekAssignCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当周空余数量">
            <a-input-number :min="0" v-model="formData.userFile.weekFreeCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当月可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.monthAvailableCount" :disabled="true" />
          </a-form-model-item> -->
      <a-form-model-item v-if="typeShow" label="擅长作品类型">
        <div class="form-select" style="width:100%" @click.stop>
          <div style="height:40px;display: flex;align-items: center;">
            <div @click.self="isTagShow()" :class="isTag ? 'tag-select-show' : 'tag-select'">
              <template
                v-if="
                  formData.userFile.goodAtProductionType && formData.userFile.goodAtProductionType.length > 0 && isShow
                "
              >
                <a-tag
                  closable
                  @close="tagClose(formValue, formData.userFile.goodAtProductionType)"
                  :key="formValue"
                  v-for="formValue in formData.userFile.goodAtProductionType"
                  >{{ formValue }}</a-tag
                >
              </template>
              <div class="tag-select-open " :style="isTag ? '' : 'height:0px;border:none'">
                <template v-if="formData.userFile.goodAtProductionType">
                  <a-checkable-tag
                    :ref="option.value"
                    v-for="option in typeList"
                    :key="option.value"
                    v-model="option.checked"
                    :style="
                      formData.userFile.goodAtProductionType.length > 5
                        ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                        : ''
                    "
                    @change="tagChange(option.checked, option.value)"
                    >{{ option.value }}</a-checkable-tag
                  >
                </template>
                <template v-else>
                  <a-checkable-tag
                    :ref="option.value"
                    v-for="option in typeList"
                    :key="option.value"
                    v-model="option.checked"
                    @change="tagChange(option.checked, option.value)"
                    >{{ option.value }}</a-checkable-tag
                  >
                </template>
              </div>
              <span class="ant-select-arrow">
                <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="isTag ? 'up' : 'down'"></a-icon>
              </span>
            </div>
          </div>
        </div>
        <!--             <a-select mode="multiple" placeholder="请选择" v-model="formData.userFile.goodAtProductionType" @change="getShow(formData.userFile.goodAtProductionType,'scShow')">
              <a-select-option :value="item" v-for="item in typeList" :key='item'>{{item}}</a-select-option>
            </a-select> -->
        <a-textarea
          v-if="scShow"
          autoSize
          v-model="formData.userFile.skilledProductOther"
          placeholder="请输入擅长的作品类型"
        />
      </a-form-model-item>
      <a-form-model-item v-if="typeShow" label="抵触作品类型">
        <!--             <a-select mode="multiple" placeholder="请选择" v-model="formData.userFile.badAtProductionType" @change="getShow(formData.userFile.badAtProductionType,'dcShow')">
              <a-select-option :value="item" v-for="item in typeList" :key='item'>{{item}}</a-select-option>
            </a-select> -->
        <div class="form-select" style="width:100%;" @click.stop>
          <div style="height:40px;display: flex;align-items: center;">
            <div @click.self="isTagShowTwo()" :class="isTagTwo ? 'tag-select-show' : 'tag-select'">
              <template
                v-if="
                  formData.userFile.badAtProductionType && formData.userFile.badAtProductionType.length > 0 && isShow
                "
              >
                <a-tag
                  closable
                  @close="tagCloseTwo(formValue, formData.userFile.badAtProductionType)"
                  :key="formValue"
                  v-for="formValue in formData.userFile.badAtProductionType"
                  >{{ formValue }}</a-tag
                >
              </template>
              <div class="tag-select-open " :style="isTagTwo ? '' : 'height:0px;border:none'">
                <template v-if="formData.userFile.badAtProductionType">
                  <a-checkable-tag
                    :ref="option.value"
                    v-for="option in typeListTwo"
                    :key="option.value"
                    v-model="option.checked"
                    :style="
                      formData.userFile.badAtProductionType.length > 5
                        ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                        : ''
                    "
                    @change="tagChangeTwo(option.checked, option.value)"
                    >{{ option.value }}</a-checkable-tag
                  >
                </template>
                <template v-else>
                  <a-checkable-tag
                    :ref="option.value"
                    v-for="option in typeListTwo"
                    :key="option.value"
                    v-model="option.checked"
                    @change="tagChangeTwo(option.checked, option.value)"
                    >{{ option.value }}</a-checkable-tag
                  >
                </template>
              </div>
              <span class="ant-select-arrow">
                <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="isTagTwo ? 'up' : 'down'"></a-icon>
              </span>
            </div>
          </div>
          <a-textarea
            v-if="dcShow"
            v-model="formData.userFile.unskilledProductOther"
            autoSize
            placeholder="请输入抵触的作品类型"
          />
        </div>
      </a-form-model-item>
      <div style="position: relative;height:20px;padding-bottom: 42px;">
        <span style="  position: absolute; ;font-size:12px;top:0;color:#aca9a9"
          >下列信息仅BOSS及HR有权查看，请如实填写。</span
        >
      </div>
      <a-form-model-item label="姓名" prop="userName">
        <a-input placeholder="请输入姓名" v-model="formData.userFile.userName" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-radio-group v-model="formData.userFile.sex">
          <a-radio :value="1">男</a-radio>
          <a-radio :value="0">女</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="出生日期" prop="bornDate">
        <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.bornDate" />
      </a-form-model-item>
      <a-form-model-item label="国籍" prop="country">
        <a-select placeholder="国籍" v-model="formData.userFile.country">
          <a-select-option value="中国">中国</a-select-option>
          <a-select-option value="美国">美国</a-select-option>
          <a-select-option value="英国">英国</a-select-option>
          <a-select-option value="日本">日本</a-select-option>
          <a-select-option value="韩国">韩国</a-select-option>
        </a-select>
      </a-form-model-item>
<!--       <a-form-model-item v-if="formData.userFile.country == '中国'" label="民族" prop="nation">
        <a-auto-complete placeholder="民族" v-model="formData.userFile.nation">
          <template slot="dataSource">
            <a-select-option v-for="n in nationList" :key="n">{{ n }}</a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item> -->
      <a-form-model-item label="电话号码" prop="phone">
        <a-input placeholder="请输入电话号码" v-model="formData.userFile.phone" />
      </a-form-model-item>
<!--       <a-form-model-item label="学历" prop="education">
        <a-select placeholder="学历" v-model="formData.userFile.education">
          <a-select-option value="博士">博士</a-select-option>
          <a-select-option value="硕士">硕士</a-select-option>
          <a-select-option value="本科">本科</a-select-option>
          <a-select-option value="专科">专科</a-select-option>
          <a-select-option value="高中">高中</a-select-option>
          <a-select-option value="初中">初中</a-select-option>
          <a-select-option value="小学">小学</a-select-option>
        </a-select>
      </a-form-model-item> -->
<!--       <a-form-model-item label="毕业院校" prop="school">
        <a-input placeholder="请输入毕业院校" v-model="formData.userFile.school" />
      </a-form-model-item> -->
<!--       <a-form-model-item label="职位相关证书" prop="certificate">
        <a-input placeholder="请输入职位相关证书" v-model="formData.userFile.certificate" />
      </a-form-model-item> -->
<!--       <a-form-model-item label="工作经历" prop="workExperience">
        <a-textarea autoSize placeholder="请输入和现在职位相关的工作经历" v-model="formData.userFile.workExperience" />
      </a-form-model-item> -->
      <template
        v-if="
          (country == 'qt' || country == 'gat') &&
            formData.userFile.contractType != '意向全职' &&
            formData.userFile.contractType != '兼职'
        "
      >
        <div style="padding-top: 10px;padding-bottom: 15px;">
          <span style="display:inline-block;width:160px;font-weight:bold;font-size:14px;">英文银行账户信息</span>
          <span style="font-size:12px;color: rgba(0,0,0,0.25);">以下内容除收款账号，其余内容请输入英文信息</span>
        </div>
        <a-form-model-item label="户名" prop="paymentName">
          <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
        </a-form-model-item>
        <a-form-model-item label="收款账号" prop="bankAccount">
          <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
        </a-form-model-item>
        <a-form-model-item label="银行名称" prop="bankName">
          <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
        </a-form-model-item>
        <a-form-model-item label="支行名称" prop="branchBank">
          <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
        </a-form-model-item>
        <a-form-model-item label="SWIFT CODE" prop="paymentBank">
          <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
        </a-form-model-item>
        <a-form-model-item label="银行地址" prop="bankAddress">
          <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
        </a-form-model-item>
        <a-form-model-item label="个人地址" prop="personalAddress">
          <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
        </a-form-model-item>
        <span style="color:red;font-size:12px;position:relative;top:-3px;"
          >*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失</span
        >
      </template>
      <template v-else>
        <div>
          <a-form-model-item
            v-if="alipayAccountShow"
            label="收款账号"
            :prop="
              country != 'qt' &&
              country != 'gat' &&
              formData.userFile.contractType != '意向全职' &&
              formData.userFile.contractType != '兼职'
                ? 'alipayAccount'
                : ''
            "
          >
            <a-select
              placeholder="请选择收款方式"
              v-if="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
              @change="paymentTypeChange"
              :default-value="formData.userFile.paymentType"
            >
              <a-select-option value="EN_ACCOUNT">
                <a-icon type="bank" style="width:1vw;position: relative;left: -3px" />
                全英账户</a-select-option
              >
              <a-select-option value="ALIPAY">
                <a-icon type="alipay-circle" style="width:1vw;position: relative;left: -3px;" />
                支付宝</a-select-option
              >
              <a-select-option value="WECHAT">
                <a-icon type="wechat" style="width:1vw;position: relative;left: -3px;" />
                微信</a-select-option
              >
              <a-select-option
                value="BANK"
                v-if="
                  formData.userFile && formData.userFile.workCity && formData.userFile.workCity.some(item => item == 83)
                "
              >
                <a-icon type="credit-card" style="width:1vw;position: relative;left: -3px;" />
                银行账户</a-select-option
              >
            </a-select>
            <a-input
              v-if="
                country != 'qt' &&
                  country != 'gat' &&
                  formData.userFile.contractType != '意向全职' &&
                  formData.userFile.contractType != '兼职'
              "
              :defaultValue="formData.userFile.alipayAccount"
              @blur="alipayAccountBlur"
              placeholder="请输入招行卡号"
            >
              <img
                slot="prefix"
                src="./../../../../assets/zh.png"
                alt=""
                style="width:1vw;position: relative;left: -4px;"
              />
            </a-input>
            <a-input
              v-if="formData.userFile.paymentType == 'ALIPAY'"
              placeholder="请输入支付宝账号"
              v-model="formData.userFile.alipayAccount"
            >
            </a-input>
            <a-input
              v-if="formData.userFile.paymentType == 'WECHAT'"
              placeholder="请输入微信账号"
              v-model="formData.userFile.alipayAccount"
            >
            </a-input>
            <template
              v-if="
                formData.userFile.paymentType == 'BANK' &&
                  formData.userFile.contractType != '全职' &&
                  formData.userFile.contractType != '实习'
              "
            >
              <a-select
                show-search
                placeholder="请选择开户行"
                option-filter-prop="label"
                v-if="country != 'qt' && country != 'gat'"
                v-model="formData.userFile.bankName"
              >
                <a-select-option :label="item.text" v-for="item in bankList" :key="item.value">{{
                  item.text
                }}</a-select-option>
              </a-select>
              <a-input
                v-if="country != 'qt' && country != 'gat'"
                placeholder="请输入银行卡号"
                @blur="submitBank"
                v-model="formData.userFile.bankAccount"
              />
              <a-input
                v-if="country != 'qt' && country != 'gat'"
                placeholder="请输入户名"
                v-model="formData.userFile.paymentName"
              />
            </template>
          </a-form-model-item>
          <template
            v-if="
              formData.userFile.paymentType == 'BANK' &&
                formData.userFile.contractType != '全职' &&
                formData.userFile.contractType != '实习' &&
                country == 'qt'
            "
          >
            <a-form-model-item label="名前片假名">
              <a-input placeholder="请输入名前片假名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="銀行名">
              <a-input placeholder="请输入銀行名" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="店番号">
              <a-input placeholder="请输入店番号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="支店名">
              <a-input placeholder="请输入支店名" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="口座番号">
              <a-input placeholder="请输入口座番号" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="預金種別">
              <a-input placeholder="请输入預金種別" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template>
          <template v-if="formData.userFile.paymentType == 'EN_ACCOUNT'">
            <div style="padding-top: 10px;padding-bottom: 15px;">
              <span style="display:inline-block;width:160px;font-weight:bold;font-size:14px;">英文银行账户信息</span>
              <span style="font-size:12px;color: rgba(0,0,0,0.25);">以下内容除收款账号，其余内容请输入英文信息</span>
            </div>
            <a-form-model-item label="户名">
              <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="收款账号">
              <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="银行名称">
              <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="支行名称">
              <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="SWIFT CODE">
              <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
            </a-form-model-item>
            <a-form-model-item label="银行地址">
              <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="个人地址">
              <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template>
        </div>
      </template>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
// 引入搜索部门弹出框的组件
import { USER_INFO } from '@/store/mutation-types'
import { duplicateCheck } from '@/api/api'
import { putAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { citys } from './city.js'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
export default {
  name: 'UserFile',
  mixins: [UploadFileToOssMixins],
  components: {},
  data() {
    return {
      textMsg: '',
      tab: '1',
      options: [],
      dis: true,
      isShow: true,
      avatarLoading: false,
      scShow: false,
      dcShow: false,
      modalWidth: 800,
      drawerWidth: 700,
      labelWidth: '160',
      userId: '', //保存用户id
      disableSubmit: false,
      dateFormat: 'YYYY-MM-DD',
      validatorRules: {
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        bornDate: [{ required: true, message: '请选择出生日期', trigger: 'blur' }],
        country: [{ required: true, message: '请选择国籍', trigger: 'blur' }],
        nation: [{ required: true, message: '请选择民族', trigger: 'blur' }],
        qq: [
          { required: true, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ],
        qqType: [
          {
            required: true,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ],
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        education: [{ required: true, message: '请选择您的学历', trigger: 'blur' }],
        school: [{ required: true, message: '请输入学校', trigger: 'blur' }],
        certificate: [{ required: true, message: '请输入职位相关证书', trigger: 'blur' }],
        workExperience: [{ required: true, message: '请输入和现在职位相关的工作经历', trigger: 'blur' }],
        workCity: [{ required: true, message: '请输入工作地城市', trigger: 'blur' }],
        contractType: [{ required: true, message: '请选择签约类型', trigger: 'blur' }],
        contractDate: [{ required: true, message: '请选择签约日期', trigger: 'change' }],
        email: [{ validator: this.validateEmail }],
        password: [{ validator: this.validateToNextPassword }],
        confirmpassword: [{ validator: this.compareToFirstPassword, trigger: 'blur' }],
        haveProbation: [{ validator: this.validateProbation }],
        probationEndDate: [{ validator: this.validateProbationEndDate }],
        workStatus: [{ required: true, message: '请选择签约类型', trigger: 'change' }],
        workdayAvailableCount: [{ required: true, message: '请填写工作日每天可安排数量', trigger: 'blur' }],
        weekendAvailableCount: [{ required: true, message: '请填写周末两天可安排数量', trigger: 'blur' }],
        weekAvailableCount: [{ required: true, message: '请填写周可安排数量', trigger: 'blur' }],
        weekAssignCount: [{ required: true, message: '请填写周已安排数量', trigger: 'blur' }],
        weekFreeCount: [{ required: true, message: '请填写周空余数量', trigger: 'blur' }],
        monthAvailableCount: [{ required: false, message: '请填写月总数量', trigger: 'blur' }],
        alipayAccount: [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            trigger: 'blur',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          }
        ],
        alipayOtherAccount: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
        goodAtProductionType: [{ required: true, message: '请输入擅长的作品类型', trigger: 'blur' }],
        badAtProductionType: [{ required: true, message: '请输入抵触的作品类型', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
        paymentName: [{ required: true, message: '请输入英文户名', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
        branchBank: [{ required: true, message: '请输入支行名称', trigger: 'blur' }],
        paymentBank: [{ required: true, message: '请输入SWIFT CODE', trigger: 'blur' }],
        bankAddress: [{ required: true, message: '请输入银行地址', trigger: 'blur' }],
        personalAddress: [{ required: true, message: '请输入个人地址', trigger: 'blur' }]
      },
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 17
        }
      },
      title: '操作',
      visible: false,
      formData: { userFile: {}, paymentType: undefined },
      curRow: {},
      selectedCompanyIds: [],
      selectedDepartIds: [],
      selectedPositionIds: [],
      selectedRoleIds: [],
      uploadLoading: false,
      saveLoading: false,
      confirmLoading: false,
      nationList: [
        '汉族',
        '满族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛南族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '朝鲜族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '德昂族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族'
      ],
      typeList: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ],
      typeListTwo: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ],
      isEdit: false,
      url: {
        edit: '/sys/user/edit_user_file'
      },
      onlyjf: [],
      allowEditBasicInfo: false,
      onlyValue: [
        {
          value: 'onlySimplified',
          label: '质检大师简'
        },
        {
          value: 'onlyTraditional',
          label: '质检大师繁'
        },
        {
          value: 'onlyJapanese',
          label: '质检大师日'
        },
        {
          value: 'onlyComparison',
          label: '质检大师对比'
        },
        {
          value: 'onlyOcr',
          label: '质检大师OCR'
        }
      ],
      allowEditBasicInfo: false,
      canUpload: false,
      isTag: false,
      isTagTwo: false,
      country: '',
      bankList: [],
      fileList: [],
      departShow: true,
      positionShow: true,
      typeShow: true,
      alipayAccountShow: true,
      isFormShow: true
    }
  },
  created() {
    this.options = citys.options
    this.bankList = citys.bankList
    this.userInfo = Vue.ls.get(USER_INFO)
    this.curRow = Object.assign({}, this.userInfo)
    if (this.curRow.userFile) {
      if (this.curRow.userFile.workCity && typeof this.curRow.userFile.workCity != 'object') {
        if (this.curRow.userFile.workCity[0] == '[') {
          this.curRow.userFile.workCity = JSON.parse(this.curRow.userFile.workCity)
        } else {
          this.curRow.userFile.workCity = this.curRow.userFile.workCity.split(',')
        }
        this.country = this.curRow.userFile.workCity[0]
      }
      if (this.curRow.userFile.badAtProductionType && typeof this.curRow.userFile.badAtProductionType != 'object') {
        this.curRow.userFile.badAtProductionType = this.curRow.userFile.badAtProductionType.split(',')
        this.curRow.userFile.badAtProductionType.forEach(item => {
          this.typeListTwo.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.dcShow = true
          }
        })
      }
      if (this.curRow.userFile.goodAtProductionType && typeof this.curRow.userFile.goodAtProductionType != 'object') {
        this.curRow.userFile.goodAtProductionType = this.curRow.userFile.goodAtProductionType.split(',')
        this.curRow.userFile.goodAtProductionType.forEach(item => {
          this.typeList.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.scShow = true
          }
        })
      }
    }
    let roles = this.userInfo.roles || []
    roles.forEach(element => {
      if (['admin', 'boss', 'hr', 'tongchou'].indexOf(element.roleCode) >= 0) {
        this.allowEditBasicInfo = true
      }
    })
    this.departShow = true
    this.positionShow = true
    this.typeShow = true
    this.curRow.roles.forEach(item => {
      if (item.roleCode == 'admin') {
        this.departShow = false
        this.positionShow = false
        this.typeShow = false
      }
      if (item.roleCode == 'boss' || item.roleCode == 'tongchou' || item.roleCode == 'hr') {
        this.typeShow = false
      }
    })
    this.curRow.userFile.paymentType = this.curRow.userFile.paymentType || undefined
    this.formData = this.curRow
  },
  mounted() {
    this.$forceUpdate()
  },
  methods: {
    qqChange() {
      let regQQ = /^[1-9][0-9]{4,9}$/
      let regWX = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
      if (regQQ.test(this.formData.userFile.qq) || regWX.test(this.formData.userFile.qqType)) {
        this.validatorRules.qq = [
          { required: false, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ]
        this.validatorRules.qqType = [
          {
            required: false,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ]
        this.isFormShow = false
        this.$nextTick(() => {
          this.isFormShow = true
        })
        setTimeout(() => {
          this.$refs.wechatDiv.$el.childNodes[0].childNodes[0].style.display = 'none'
        }, 0)
      } else if (!this.formData.userFile.qq && !this.formData.userFile.qqType) {
        this.validatorRules.qq = [
          { required: true, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ]
        this.validatorRules.qqType = [
          {
            required: true,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ]
      }
    },
    alipayAccountBlur(e) {
      this.formData.userFile.alipayAccount = e.target.value.replace(/\s*/g, '')
    },
    drawerClick() {
      console.log('drawerClick')
      this.isTag = false
      this.isTagTwo = false
    },
    getBank(name) {
      let text = ''
      citys.bankList.forEach(item => {
        if (item.value == name) {
          text = item.text
          return
        }
      })
      return text
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    },
    contractTypeChange() {
      this.formData.userFile.bankAccount = ''
      this.formData.userFile.paymentName = ''
      this.formData.userFile.bankName = ''
      this.formData.userFile.branchBank = ''
      this.formData.userFile.paymentBank = ''
      this.formData.userFile.bankAddress = ''
      this.formData.userFile.personalAddress = ''
      this.formData.userFile.paymentType = null
      this.formData.userFile.alipayAccount = ''
      this.formData.userFile.paymentName = ''
    },
    paymentTypeChange(value) {
      this.formData.userFile.paymentType = value
      this.alipayAccountShow = false
      this.formData.userFile.alipayAccount = ''
      this.$nextTick(() => {
        this.alipayAccountShow = true
      })
      this.$forceUpdate()
    },
    submitBank() {
      let str = this.formData.userFile.bankAccount.replace(/\s*/g, '')
      axios
        .get(
          `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${str}&cardBinCheck=true`
        )
        .then(res => {
          const { bank, cardType, validated, messages } = res.data
          if (validated) {
            this.formData.userFile.bankName = bank
          }
        })
    },
    tagChangeTwo(e, option) {
      if (!this.formData.userFile.badAtProductionType) this.formData.userFile.badAtProductionType = []
      if (e) {
        if (typeof this.formData.userFile.badAtProductionType == 'object') {
          this.formData.userFile.badAtProductionType.push(option)
        } else {
        }
      } else {
        let deleteIndex = this.formData.userFile.badAtProductionType
          .map(item => {
            return item
          })
          .indexOf(option)
        if (!this.formData.userFile.badAtProductionType) {
          this.formData.userFile.badAtProductionType = []
        } else {
          this.formData.userFile.badAtProductionType.splice(deleteIndex, 1)
        }
      }
      console.log(this.formData.userFile.badAtProductionType, 456)
      this.getShow(this.formData.userFile.badAtProductionType, 'dcShow')
    },
    tagChange(e, option) {
      if (!this.formData.userFile.goodAtProductionType) this.formData.userFile.goodAtProductionType = []
      if (e) {
        if (typeof this.formData.userFile.goodAtProductionType == 'object') {
          this.formData.userFile.goodAtProductionType.push(option)
        } else {
        }
      } else {
        let deleteIndex = this.formData.userFile.goodAtProductionType
          .map(item => {
            return item
          })
          .indexOf(option)
        if (!this.formData.userFile.goodAtProductionType) {
          this.formData.userFile.goodAtProductionType = []
        } else {
          this.formData.userFile.goodAtProductionType.splice(deleteIndex, 1)
        }
      }
      this.getShow(this.formData.userFile.goodAtProductionType, 'scShow')
    },
    tagClose(formValue, formValues) {
      if (formValue == '其他') {
        this.formData.userFile.skilledProductOther = ''
        this.scShow = false
      }
      this.$nextTick(() => {
        this.typeList[
          this.typeList
            .map(item => {
              return item.value
            })
            .indexOf(formValue)
        ].checked = false
        let deleteIndex = formValues
          .map(item => {
            return item
          })
          .indexOf(formValue)
        formValues.splice(deleteIndex, 1)
      })
    },
    tagCloseTwo(formValue, formValues) {
      if (formValue == '其他') {
        this.formData.userFile.unskilledProductOther = ''
        this.dcShow = false
      }
      this.$nextTick(() => {
        this.typeListTwo[
          this.typeListTwo
            .map(item => {
              return item.value
            })
            .indexOf(formValue)
        ].checked = false
        let deleteIndex = formValues
          .map(item => {
            return item
          })
          .indexOf(formValue)
        formValues.splice(deleteIndex, 1)
      })
    },
    isTagShow() {
      this.isTag = !this.isTag
      this.isTagTwo = false
    },
    isTagShowTwo() {
      this.isTagTwo = !this.isTagTwo
      this.isTag = false
    },
    onChange(value) {
      this.country = value[0] || ''
      this.formData.userFile.workCity = value
      this.formData.userFile.paymentType = undefined
    },
    filter(inputValue, path) {
      console.log(path)
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    getShow(list, type) {
      if (list) {
        if (list.length > 0) {
          if (
            list.some(item => {
              return item == '其他'
            })
          ) {
            if (type == 'scShow') {
              this.scShow = true
            } else {
              this.dcShow = true
            }
          } else {
            if (type == 'scShow') {
              this.scShow = false
            } else {
              this.dcShow = false
            }
          }
        } else {
          if (type == 'scShow') {
            this.scShow = false
          } else {
            this.dcShow = false
          }
        }
      } else {
        if (type == 'scShow') {
          this.scShow = false
        } else {
          this.dcShow = false
        }
      }
    },
    callback(key) {
      this.tab = key
    },
    handleUpChange({ file, fileList }) {
      return false
    },
    beforeCroUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      /* this.formData.userFile.avatar = url */
      this.textMsg = ''
      this.$emit('openCropper', url)
      return false
    },
    async pasting(event) {
      let txt = event.clipboardData.getData('Text')
      if (typeof txt == 'string') {
        this.textMsg += txt
        /* this.formData.userFile.avatar = txt */
        this.$emit('openCropper', txt)
      }
      let file = null
      const items = (event.clipboardData || window.clipboardData).items
      if (items.length) {
        this.avatarLoading = true
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile()
            this.handleChange(file)
            if (!this.canUpload) {
              this.canUpload = !this.canUpload
            }
            break
          }
        }
      }
    },
    async upImg(file) {
      console.log(file,123)
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file })
        })
    },
    handleChange(file, filelist) {
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      /* this.formData.userFile.avatar = url */
      this.$emit('openCropper', url)
      this.textMsg = ''
      return false
    },
    editButtonClick() {
      if (!this.isEdit) {
        this.isEdit = true
      } else {
        this.saveForm()
      }
    },
    onlyChange(value) {
      this.formData.onlySimplified = false
      this.formData.onlyTraditional = false
      this.formData.onlyJapanese = false
      this.formData.onlyComparison = false
      this.formData.onlyOcr = false
      if (value.length > 0) {
        value.forEach(key => {
          this.formData[key] = true
        })
      }
    },
    saveForm() {
      let self = this
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.saveLoading = true
          //修改一下form的数据
          if (this.formData.id) {
            this.formData.userFile.userId = this.formData.id
          }
          if (this.formData.userFile.bornDate && this.formData.userFile.bornDate.format) {
            this.formData.userFile.bornDate = this.formData.userFile.bornDate.format(this.dateFormat)
          }
          if (this.formData.userFile.contractDate && this.formData.userFile.contractDate.format) {
            this.formData.userFile.contractDate = this.formData.userFile.contractDate.format(this.dateFormat)
          }
          if (this.formData.userFile.probationEndDate && this.formData.userFile.probationEndDate.format) {
            this.formData.userFile.probationEndDate = this.formData.userFile.probationEndDate.format(this.dateFormat)
          }
          if (this.formData.userFile.workCity && typeof this.formData.userFile.workCity == 'object') {
            this.formData.userFile.workCity = this.formData.userFile.workCity.join(',')
          }
          if (
            this.formData.userFile.goodAtProductionType &&
            typeof this.formData.userFile.goodAtProductionType == 'object'
          ) {
            this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType.join(',')
          }
          if (
            this.formData.userFile.badAtProductionType &&
            typeof this.formData.userFile.badAtProductionType == 'object'
          ) {
            this.formData.userFile.badAtProductionType = this.formData.userFile.badAtProductionType.join(',')
          }
          this.isShow = false
          this.formData.needWorkDays = false
          let workInfoTimer = true
          if (!this.formData.userFile.userName) workInfoTimer = false
          if (!this.formData.userFile.sex) workInfoTimer = false
          if (!this.formData.userFile.bornDate) workInfoTimer = false
          if (!this.formData.userFile.country) workInfoTimer = false
          if (!this.formData.userFile.nation) workInfoTimer = false
          if (!this.formData.userFile.phone) workInfoTimer = false
          if (!this.formData.userFile.education) workInfoTimer = false
          if (!this.formData.userFile.certificate) workInfoTimer = false
          if (!this.formData.userFile.school) workInfoTimer = false
          if (!this.formData.userFile.workExperience) workInfoTimer = false
          if (
            (this.country == 'qt' || this.country == 'gat') &&
            this.formData.userFile.contractType != '意向全职' &&
            this.formData.userFile.contractType != '兼职'
          ) {
            if (!this.formData.userFile.bankAccount) workInfoTimer = false
            if (!this.formData.userFile.paymentName) workInfoTimer = false
            if (!this.formData.userFile.bankName) workInfoTimer = false
            if (!this.formData.userFile.branchBank) workInfoTimer = false
            if (!this.formData.userFile.paymentBank) workInfoTimer = false
            if (!this.formData.userFile.bankAddress) workInfoTimer = false
            if (!this.formData.userFile.personalAddress) workInfoTimer = false
          }
          if (
            this.country != 'qt' &&
            this.country != 'gat' &&
            this.formData.userFile.contractType != '意向全职' &&
            this.formData.userFile.contractType != '兼职'
          ) {
            if (!this.formData.userFile.alipayAccount) {
              workInfoTimer = false
            } else {
              this.formData.userFile.alipayAccount = this.formData.userFile.alipayAccount.replace(/\s*/g, '')
            }
          }
          if (workInfoTimer) {
            putAction('/sys/user/changeWorkInfoTimer', {})
          }
          putAction(self.url.edit, self.formData).then(res => {
            this.saveLoading = false
            this.isEdit = false
            if (res.success) {
              self.$message.success(res.message)
              this.userInfo.userFile.avatar = this.formData.userFile.avatar
              Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
              this.$emit('changeUserTab')
            } else {
              self.$message.error(res.message)
            }
          })
        }
      })
    },
    setFormData() {},
    moment,
    validateToNextPassword(rule, value, callback) {
      const confirmpassword = this.formData.confirmpassword

      if (value && confirmpassword && value !== confirmpassword) {
        callback('两次输入的密码不一样！')
      }
      callback()
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.formData.password) {
        callback('两次输入的密码不一样！')
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      if (this.isEdit) {
        callback()
        return
      }
      if (!value) {
        callback()
      } else {
        if (
          new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          var params = {
            tableName: 'sys_user',
            fieldName: 'email',
            fieldVal: value,
            dataId: this.userId
          }
          duplicateCheck(params).then(res => {
            console.log(res)
            if (res.success) {
              callback()
            } else {
              callback('邮箱已存在!')
            }
          })
        } else {
          callback('请输入正确格式的邮箱!')
        }
      }
    },
    validateProbation(rule, value, callback) {
      if (this.formData.userFile.contractType == '全职') {
        if (this.formData.userFile.haveProbation != '1') {
          callback('签约全职时必须选择试用期')
          return
        }
      }
      callback()
    },
    validateProbationEndDate(rule, value, callback) {
      if (this.formData.userFile.contractType == '全职') {
        if (!this.formData.userFile.probationEndDate) {
          callback('签约全职时必须选择试用期结束时间')
          return
        }
      }
      callback()
    },
    handleChangeUpload(info, index) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.userFile.avatar = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload_img {
  max-width: 120px;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .qq-select {
  .ant-select-arrow {
    display: none !important;
  }
}
/deep/ .ant-drawer-body {
  padding-right: 10px !important;
}
/deep/ .ant-tabs-bar {
  margin-bottom: 8vh !important;
}
/deep/ .pb-14 {
  padding-bottom: 0 !important;
}
/deep/ .user-photo {
  .ant-form-item-children {
    display: flex !important;
    align-items: center;
    .avatar-uploader {
      padding-right: 20px;
      width: auto !important;
    }
  }
}
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}

.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

/deep/ .avatar-uploader > .ant-upload {
  width: 122px !important;
  height: 122px !important;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.img-div {
  width: 120px;
  height: 120px;
  border-radius: 50% !important;
  overflow: hidden;
}
.upload_img {
  width: auto;
  height: 100%;
}
/deep/ .ant-tabs-tab {
  font-size: 1rem !important;
  font-weight: 500;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  border-radius: 50% !important;
}
/deep/ .ant-upload.ant-upload-select-picture-card:hover {
  border-color: var(--theme-color) !important;
}
/deep/ .ant-upload {
  padding: 0 !important;
}
.tag-select {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(124, 170, 235);
  }
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
}
.tag-select-show {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  box-shadow: 0 0 0 1.5px rgb(124, 170, 235);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
  .ant-tag-checkable-checked {
    background-color: #5389df;
    color: #fff;
    cursor: pointer;
  }
}
/deep/ .tag-select-open {
  position: absolute;
  border: 1px solid #d9d9d9;
  width: 428px;
  top: 36px;
  display: flex;
  left: -2px;
  height: max-content;
  padding-bottom: 3px;
  overflow-y: overlay;
  flex-wrap: wrap;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(235, 233, 233);
  transition: 0.2s;
  align-content: flex-start;
  z-index: 1000;
  .ant-tag {
    height: 25px;
    margin-top: 5px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
.tag-select-close {
  position: absolute;
  display: none;
  transition: 0.2s;
  width: 428px;
  top: 30px;
  left: -2px;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px black;
}
</style>
